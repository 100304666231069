import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import SliceZone from '../components/SliceZone';

const Page = ({data: {prismicBasicPage}, location}) => {
  const {data} = prismicBasicPage;
  return (
    <Layout customSEO headerColor={data.initial_header_colour} keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']}>
      <SEO title={`${data.page_title.text}`} />
      <div className="header-spacer-mobile" />
      <SliceZone allSlices={data.body} />
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  location: PropTypes.object.isRequired
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicBasicPage(uid: {eq: $uid}) {
      uid
      data {
        page_title {
          text
        }
        initial_header_colour
        body {
          __typename
          ... on PrismicBasicPageBodyBannerImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBasicPageBodyCenteredTextBlock {
            slice_type
            id
            primary {
              title {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicBasicPageBodyImageText {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                html
              }
              text_position
              border_top
              border_bottom
            }
          }
          ... on PrismicBasicPageBodyQuote {
            slice_type
            id
            primary {
              quote {
                text
              }
              author {
                text
              }
            }
          }
          ... on PrismicBasicPageBodySpacer {
            slice_type
            id
          }
          ... on PrismicBasicPageBodyCenteredTitleBlock {
            slice_type
            id
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicBasicPageBodyServicesSlider {
            slice_type
            id
          }
          ... on PrismicBasicPageBodyTwoColumnText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBasicPageBodyImageCarousel {
            slice_type
            id
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBasicPageBodyTwoUpCard {
            slice_type
            id
            primary {
              card_one_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              card_one_title {
                text
              }
              card_one_text {
                text
              }
              card_two_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              card_two_title {
                text
              }
              card_two_text {
                text
              }
            }
          }
          ... on PrismicBasicPageBodyFaqs {
            slice_type
            id
            items {
              title {
                text
              }
              text {
                html
              }
              link_text {
                text
              }
              link {
                url
                link_type
              }
            }
          }
        }
      }
    }
  }
`;
